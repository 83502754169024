<template>
  <div class="mx-4 flex">
    <a
      class="w-full cursor-pointer lg:w-[343px]"
      :href="props.seeMoreLink"
      @click.prevent="onClick"
    >
      <div
        class="card-single relative h-auto w-full rounded-2xl lg:h-[343px] lg:w-[343px]"
      >
        <HHImage
          :src="props.image"
          alt="restaurant image"
          :width="343"
          :height="343"
          class="w-full rounded-2xl"
          :use-mutator="true"
        />
        <div
          class="card-overlay absolute left-0 top-0 h-full w-full rounded-2xl"
        >
          <div class="flex h-full w-full flex-col justify-between p-4">
            <div>
              <div>
                <span class="text-xs font-bold uppercase text-white">{{
                  props.title
                }}</span>
              </div>
              <div>
                <span class="text-2xl font-bold uppercase text-white">{{
                  props.subTitle
                }}</span>
              </div>
            </div>
            <div>
              <span class="text-xs font-normal capitalize text-white">{{
                props.description
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
import HHImage from "~/components/HhImage.vue";
import { type HomepageModelEvent } from "~/types/Home";
import { BASE_URL_WEB_PRODUCTION } from "~/constants";

interface Props {
  isLoading: boolean;
  seeMoreLink: string;
  image: string;
  title: string;
  template: string;
  subTitle?: string;
  description?: string;
}

const emits = defineEmits<{
  (e: "on-group-clicked", data: HomepageModelEvent): void;
}>();

const props = defineProps<Props>();

function onClick() {
  emits("on-group-clicked", {
    sectionName: props.title,
    sectionTemplate: props.template,
    slug: `${BASE_URL_WEB_PRODUCTION}/${props.seeMoreLink}`,
  });
}

defineOptions({
  name: "CardSingle",
});
</script>

<style lang="scss">
.card-single {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.08);

  .card-overlay {
    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 29.88%),
      linear-gradient(180deg, rgba(0, 0, 0, 0) 74.75%, #000 100%), no-repeat;
  }
}
</style>
